export const SUPER_ADMIN_ROLE = 'super-admin'
export const ADMIN_ROLE = 'admin'

export const ROLES = {
  [SUPER_ADMIN_ROLE]: {
    name: 'Super Admin',
    color: 'success',
  },
  [ADMIN_ROLE]: {
    name: 'Admin',
    color: 'error',
  },
}

export const ROLE_COLORS = ['success', 'error', 'warning', 'secondary']
