import React from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { LOGIN_PATH } from '../constants/paths'
import { useAuth } from '../context/AuthProvider'
import { ADMIN_ROLE, SUPER_ADMIN_ROLE } from '../constants/roles'

const PrivateRoute = ({ roles, children }) => {
  const { isAuthenticated, isSuperAdmin, isAdmin } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  const isAuthorized =
    (roles.includes(SUPER_ADMIN_ROLE) && isSuperAdmin) ||
    (roles.includes(ADMIN_ROLE) && (isSuperAdmin || isAdmin))

  // if (!isAuthenticated || !isAuthorized) {
  //   // return <Navigate to={LOGIN_PATH} replace />
  //   return navigate(LOGIN_PATH, { state: { from: location.pathname } })
  // }

  if (!isAuthorized) return ''

  return children
}

export default PrivateRoute
