import { DELETE, GET, POST } from '../../constants/methods'

// Auth
export const LOGIN = { method: POST, url: 'auth/login' }
export const LOGOUT = { method: POST, url: 'auth/logout' }
export const GET_USER_INFO_BY_TOKEN = { method: GET, url: 'user' }

// User
export const GET_LIST_USER = { method: GET, url: 'users' }
export const CREATE_USER = { method: POST, url: 'user' }
export const UPDATE_USER = { method: POST, url: 'user/:id' }
export const DELETE_USER = { method: DELETE, url: 'user/:id' }

// Business setting
export const GET_LIST_BUSINESS = { method: GET, url: 'business_setting' }
export const CREATE_BUSINESS = { method: POST, url: 'business_setting' }
export const UPDATE_BUSINESS = { method: POST, url: 'business_setting/:id' }
export const DELETE_BUSINESS = { method: DELETE, url: 'business_setting/:id' }

// Business location
export const GET_LIST_BUSINESS_LOCATION = { method: GET, url: 'business_location' }
export const CREATE_BUSINESS_LOCATION = { method: POST, url: 'business_location' }
export const UPDATE_BUSINESS_LOCATION = { method: POST, url: 'business_location/:id' }
export const DELETE_BUSINESS_LOCATION = { method: DELETE, url: 'business_location/:id' }

// Role
export const GET_LIST_ROLE = { method: GET, url: 'master/roles' }
export const CREATE_ROLE = { method: POST, url: 'master/roles' }
export const UPDATE_ROLE = { method: POST, url: 'master/roles/:id' }
export const DELETE_ROLE = { method: DELETE, url: 'master/roles/:id' }

// Unit
export const GET_LIST_UNIT = { method: GET, url: 'uom' }
export const CREATE_UNIT = { method: POST, url: 'uom' }
export const UPDATE_UNIT = { method: POST, url: 'uom/:id' }
export const DELETE_UNIT = { method: DELETE, url: 'uom/:id' }

// Categories
export const GET_LIST_CATEGORIES = { method: GET, url: 'categories' }
export const CREATE_CATEGORIES = { method: POST, url: 'categories' }
export const UPDATE_CATEGORIES = { method: POST, url: 'categories/:id' }
export const DELETE_CATEGORIES = { method: DELETE, url: 'categories/:id' }

// Machine
export const GET_LIST_MACHINE = { method: GET, url: 'machine' }
export const CREATE_MACHINE = { method: POST, url: 'machine' }
export const UPDATE_MACHINE = { method: POST, url: 'machine/:id' }
export const DELETE_MACHINE = { method: DELETE, url: 'machine/:id' }

// Product
export const GET_LIST_PRODUCT = { method: GET, url: 'product' }
export const CREATE_PRODUCT = { method: POST, url: 'product' }
export const UPDATE_PRODUCT = { method: POST, url: 'product/:id' }
export const DELETE_PRODUCT = { method: DELETE, url: 'product/:id' }

// Master data
export const GET_LIST_GALLERY = { method: GET, url: 'upload' }
export const GET_LIST_CURRENCY = { method: GET, url: 'master/currencies' }
export const GET_LIST_TIMEZONE = { method: GET, url: 'master/timezones' }

export const GET_LIST_COUNTRY = { method: GET, url: 'master/countries' }
export const GET_LIST_PROVINCE = { method: GET, url: 'master/provinces' }
export const GET_LIST_DISTRICT = { method: GET, url: 'master/districts' }

export const UPLOAD_IMAGE = { method: POST, url: 'upload', isFormData: true }
