import axios from 'axios'
import stringify from 'query-string'
import { generatePath } from 'react-router'
import { DELETE, GET, PATCH, POST, PUT } from '../../constants/methods'

export async function fetcher(requestApi, params = {}, urlParams = null, config = {}) {
  const version = import.meta.env.VITE_API_VERSION
  let requestUrl = ''

  const requestApiUrl = requestApi.isAbsoluteUrl
    ? requestApi.url
    : `/${version}${requestApi.prefix ? `/${requestApi.prefix}/` : '/'}${requestApi.url}`

  let requestParams = { ...params }
  let resErrors = {}
  let resStatus = ''

  const configFormData = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }

  switch (requestApi.method) {
    case GET:
      let queryParams = null
      if (params && params.query) {
        queryParams = params.query
        delete requestParams.query
      }

      requestUrl = generatePath(`${requestApiUrl}`, urlParams ? urlParams : requestParams)
      if (queryParams) requestUrl = `${requestUrl}?${stringify(queryParams)}`

      try {
        const res = await axios.get(requestUrl, {
          params: requestParams,
          ...(config && {
            ...config,
          }),
        })

        return {
          data: res.data,
          success: true,
          status: res.status,
        }
      } catch (e) {
        if (e.response && e.response.data) {
          resErrors = e.response.data
          resStatus = e.response.status
        }
        return {
          success: false,
          errors: resErrors,
          status: resStatus,
        }
      }

    case POST:
      requestUrl = generatePath(`${requestApiUrl}`, urlParams ? urlParams : requestParams)
      if (urlParams) requestUrl = `${requestUrl}?${stringify(urlParams)}`

      try {
        const res = await axios.post(
          requestUrl,
          params,
          requestApi.isFormData
            ? {
              ...configFormData,
              ...(config && {
                ...config,
              }),
            }
            : {
              ...(config && {
                ...config,
              }),
            }
        )

        return {
          data: res.data,
          success: true,
          status: res.status,
        }
      } catch (e) {
        if (e.response && e.response.data) {
          resErrors = e.response.data
          resStatus = e.response.status
        }
        return {
          success: false,
          errors: resErrors,
          status: resStatus,
        }
      }

    case PUT:
    case PATCH:
      requestUrl = generatePath(`${requestApiUrl}`, urlParams ? urlParams : requestParams)
      try {
        //Append method if this is a form data
        if (requestApi.isFormData) params.append('_method', requestApi.method)
        const requestFunc = requestApi.method === PATCH ? axios.patch : axios.put
        const res = await requestFunc(
          requestUrl,
          params,
          requestApi.isFormData ? configFormData : {}
        )

        return {
          data: res.data,
          success: true,
          status: res.status,
        }
      } catch (e) {
        if (e.response && e.response.data) {
          resErrors = e.response.data
          resStatus = e.response.status
        }
        return {
          success: false,
          errors: resErrors,
          status: resStatus,
        }
      }

    case DELETE:
      requestUrl = generatePath(`${requestApiUrl}`, requestParams)

      try {
        const res = await axios.delete(requestUrl, { data: params })
        return {
          data: res.data,
          success: true,
          status: res.status,
        }
      } catch (e) {
        if (e.response && e.response.data) {
          resErrors = e.response.data
          resStatus = e.response.status
        }
        return {
          success: false,
          errors: resErrors,
          status: resStatus,
        }
      }

    default:
  }
}
