import { atom } from 'recoil'

export const currenciesState = atom({
  key: 'currenciesState',
  default: [],
})

export const timezonesState = atom({
  key: 'timezonesState',
  default: [],
})

export const galleriesState = atom({
  key: 'galleriesState',
  default: [],
})

export const countriesState = atom({
  key: 'countriesState',
  default: [],
})
