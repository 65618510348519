import { CssBaseline, ThemeProvider } from '@mui/material'
import { useSelector } from 'react-redux'
import { useRoutes } from 'react-router-dom'
import ScrollToTop from './components/shared/ScrollToTop'
import { AuthProvider } from './context/AuthProvider'
import RTL from './layouts/full/shared/customizer/RTL'
import Router from './routes/Router'
import { ThemeSettings } from './theme/Theme'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { RecoilRoot } from 'recoil'

function App() {
  const routing = useRoutes(Router)
  const theme = ThemeSettings()
  const customizer = useSelector(state => state.customizer)

  // Cấu hình QueryClient mặc định
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 5 * 60 * 1000,
        cacheTime: 10 * 60 * 1000,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
        retry: 1,
      },
    },
  })

  return (
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <RTL direction={customizer.activeDir}>
              <CssBaseline />
              <ScrollToTop>{routing}</ScrollToTop>
            </RTL>
          </ThemeProvider>
        </AuthProvider>
      </QueryClientProvider>
    </RecoilRoot>
  )
}

export default App
