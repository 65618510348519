import axios from 'axios'
import Cookies from 'js-cookie'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useSetRecoilState } from 'recoil'
import { BIO_TOKEN, EXPIRES_TOKEN } from '../../constants/auth'
import { HOME_PATH, LOGIN_PATH } from '../../constants/paths'
import { ROLE_COLORS } from '../../constants/roles'
import {
  countriesState,
  currenciesState,
  galleriesState,
  timezonesState,
} from '../../recoil/master'
import { rolesState } from '../../recoil/roles'
import {
  GET_LIST_COUNTRY,
  GET_LIST_CURRENCY,
  GET_LIST_GALLERY,
  GET_LIST_ROLE,
  GET_LIST_TIMEZONE,
  GET_USER_INFO_BY_TOKEN,
  LOGIN,
  LOGOUT,
} from '../../utils/fetch/apiSpecs'
import { fetcher } from '../../utils/fetch/fetch'

axios.defaults.baseURL = import.meta.env.VITE_API_URL
// axios.defaults.withCredentials = false

// Tạo AuthContext
const AuthContext = createContext({
  user: null,
  isAuthenticated: false,

  onLogin: async () => {},
  onLogout: async () => {},
})

// Custom hook để sử dụng AuthContext
export const useAuth = () => {
  return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isSuperAdmin, setIsSuperAdmin] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [user, setUser] = useState(null)

  const setRoles = useSetRecoilState(rolesState)
  const setCurrencies = useSetRecoilState(currenciesState)
  const setTimezones = useSetRecoilState(timezonesState)
  const setGalleries = useSetRecoilState(galleriesState)
  const setCountries = useSetRecoilState(countriesState)

  //Set authorization header token for all request
  const setAuthorizationToken = async params => {
    const { success, token, remember, user } = params || {}
    if (success) {
      if (user) {
        setUser(user)
        setIsSuperAdmin(user.is_super_admin)
        setIsAdmin(user.is_admin)
      }
      if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        Cookies.set(BIO_TOKEN, token, {
          sameSite: 'none',
          secure: true,
          ...(remember && {
            expires: EXPIRES_TOKEN, // hết hạn sau 7 ngày
          }),
        })
      }
      const response = await fetcher(GET_LIST_ROLE)
      setRoles(
        response?.data?.data.map((role, i) => ({
          ...role,
          color: ROLE_COLORS[i],
        })) || []
      )
      setIsAuthenticated(true)
      if (location.pathname?.includes(LOGIN_PATH)) {
        navigate(location.state?.from || HOME_PATH, { replace: true })
      }
    } else {
      setIsAuthenticated(false)
      delete axios.defaults.headers.common['Authorization']
      Cookies.remove(BIO_TOKEN)
      navigate(LOGIN_PATH, { state: { from: location.pathname } })
    }
  }

  const onLogin = async (data, handleErrorLogin) => {
    try {
      const response = await fetcher(LOGIN, data)
      if (response.success) {
        setAuthorizationToken({
          success: true,
          user: response.data,
          token: response.data.access_token,
          remember: data.remember,
        })
      } else {
        handleErrorLogin(response.errors)
      }
    } catch (error) {
      handleErrorLogin({ message: 'Login error!' })
      setAuthorizationToken(false)
    }
  }

  const onLogout = async () => {
    await fetcher(LOGOUT)
    setAuthorizationToken(false)
  }

  useEffect(async () => {
    const UNAUTHORIZED = 401
    axios.interceptors.response.use(
      response => response,
      async error => {
        const { status } = error.response
        if (status === UNAUTHORIZED) {
          setAuthorizationToken(false)
        }
      }
    )

    const token = Cookies.get(BIO_TOKEN)
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    const response = await fetcher(GET_USER_INFO_BY_TOKEN)
    if (response?.success) {
      setAuthorizationToken({
        success: true,
        user: response.data,
      })
    } else {
      setAuthorizationToken(false)
    }
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      let isMounted = true

      const getCurrencies = async () => {
        const response = await fetcher(GET_LIST_CURRENCY)
        isMounted && setCurrencies(response?.data?.data || [])
      }

      const getTimezones = async () => {
        const response = await fetcher(GET_LIST_TIMEZONE)
        isMounted && setTimezones(response?.data?.data || [])
      }

      const getGalleries = async () => {
        const response = await fetcher(GET_LIST_GALLERY)
        isMounted && setGalleries(response?.data?.data || [])
      }

      const getCountries = async () => {
        const response = await fetcher(GET_LIST_COUNTRY)
        isMounted && setCountries(response?.data?.data || [])
      }

      getCurrencies()
      getTimezones()
      getGalleries()
      getCountries()

      return () => {
        isMounted = false
      }
    }
  }, [isAuthenticated])

  return (
    <AuthContext.Provider
      value={{
        user: user,
        setUser: setUser,

        isAuthenticated: isAuthenticated,
        isSuperAdmin: isSuperAdmin,
        isAdmin: isAdmin,

        onLogin: onLogin,
        onLogout: onLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
